import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { selectUser } from '../../store/selectors/user.selector';
import { ADMIN_JOBS_PAGE_URL } from '../admin/jobs/jobs.page';
import { JOBS_AND_TESTS_PAGE_URL } from '../dashboard/jobs-and-tests/jobs-and-tests.page';

export const ERROR_PAGE_URL = 'not-found';

export const errorRoutes: Route[] = [
  {
    path: ERROR_PAGE_URL,
    loadComponent: () => NotFoundErrorPage,
  },
  {
    path: '**',
    redirectTo: ERROR_PAGE_URL,
  },
];

@Component({
  standalone: true,
  templateUrl: './not-found-error.page.html',
  imports: [CommonModule],
})
export class NotFoundErrorPage {
  public constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  public async navigateToDashboard(): Promise<void> {
    const { user } = await firstValueFrom(this.store.select(selectUser));
    if (!user) {
      await this.router.navigateByUrl('auth/login', { replaceUrl: true });
    }

    if (user?.profile.group === 'ADMIN') {
      await this.router.navigateByUrl('admin/' + ADMIN_JOBS_PAGE_URL, {
        replaceUrl: true,
      });
    } else {
      await this.router.navigateByUrl(JOBS_AND_TESTS_PAGE_URL, {
        replaceUrl: true,
      });
    }
  }
}
