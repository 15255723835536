import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Find, Loqate, Retrieve } from '../../interfaces/loqate.interface';
import { LoqateService } from '../../services/loqate.service';
import { LoqateActions } from '../actions/loqate.action';

@Injectable()
export class LoqateEffects {
  public find$: Observable<Action> = createEffect(() => {
    let searchTerm: string;
    return this.actions$.pipe(
      ofType(LoqateActions.find),
      switchMap((request) => {
        searchTerm = request.find;
        return from(this.service.find(request.find)).pipe(
          map((data: Loqate<Find>) => {
            if (!data) {
              throw new Error();
            }

            data.searchTerm = searchTerm;
            return LoqateActions.findSuccess({ data });
          }),
          catchError((error: unknown) =>
            of(LoqateActions.findFailure({ error })),
          ),
        );
      }),
    );
  });

  public findFailure$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoqateActions.findFailure),
        tap(() =>
          console.log(
            'Unable to find address details. Please check your search term and try again.',
            'error',
          ),
        ),
      );
    },
    { dispatch: false },
  );

  public findContainer$: Observable<Action> = createEffect(() => {
    let searchTerm: string;
    return this.actions$.pipe(
      ofType(LoqateActions.findContainer),
      switchMap((request) => {
        searchTerm = request.container.searchTerm;
        return from(
          this.service.findContainer(
            request.container.searchTerm,
            request.container.id,
          ),
        ).pipe(
          map((payload: Loqate<Find>) => {
            payload.searchTerm = searchTerm;
            return LoqateActions.findContainerSuccess({
              payload,
              containerId: request.container.id,
            });
          }),
          catchError((error: unknown) =>
            of(LoqateActions.findContainerFailure({ error })),
          ),
        );
      }),
    );
  });

  public findContainerFailure$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoqateActions.findContainerFailure),
        tap(() =>
          console.log(
            'Unable to find address details. Please check your search term and try again.',
            'error',
          ),
        ),
      );
    },
    { dispatch: false },
  );

  public retrieve$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoqateActions.retrieve),
      switchMap((request) =>
        from(this.service.retrieve(request.id)).pipe(
          map((payload: Loqate<Retrieve>) =>
            LoqateActions.retrieveSuccess({ payload }),
          ),
          catchError((error: unknown) =>
            of(LoqateActions.retrieveFailure({ error })),
          ),
        ),
      ),
    );
  });

  public retrieveFailure$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoqateActions.retrieveFailure),
        tap(() =>
          console.log(
            'Unable to find address details. Please check your search term and try again.',
            'error',
          ),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private service: LoqateService,
  ) {}
}
