import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIResponse } from '../interfaces/api.interface';
import {
  Job,
  JobCreate,
  JobSearchParams,
  JobUpdate,
} from '../interfaces/job.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  public constructor(private readonly api: ApiService) {}

  public create(organisationId: string, data: JobCreate): Observable<Job> {
    return this.api.post<Job, JobCreate>(
      `organisations/${organisationId}/orders`,
      data,
    );
  }

  public get(organisationId: string, jobId: string): Observable<Job> {
    return this.api.get<Job>(
      `admin/organisations/${organisationId}/orders/${encodeURIComponent(jobId)}`,
    );
  }

  public search(params?: JobSearchParams): Observable<APIResponse<Job[]>> {
    let path = `admin/orders`;

    if (params) {
      Object.entries(params).forEach(([key, value], index) => {
        return index === 0
          ? (path += `?${key}=${value}`)
          : (path += `&${key}=${value}`);
      });
    }

    return this.api.get<APIResponse<Job[]>>(path);
  }

  public update(
    organisationId: string,
    jobId: string,
    data: JobUpdate,
  ): Observable<Job> {
    return this.api.put<Job, JobUpdate>(
      `organisations/${organisationId}/orders/${jobId}`,
      data,
    );
  }
}
