<div class="min-w-screen flex h-screen flex-col md:flex-row">
  <app-sidebar />

  <main
    class="flex w-full flex-col overflow-x-auto px-6 pt-6 md:px-12 md:pt-10"
  >
    <app-alerts />

    <router-outlet />
  </main>
</div>
