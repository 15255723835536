<!-- Mobile Navbar -->
<div *ngIf="isMobileView" class="flex items-center justify-between p-6">
  <img class="h-auth w-24" src="/assets/images/logo.svg" alt="" />

  <button
    class="h-10 w-10 rounded bg-alpha-blue-10 p-2"
    (click)="toggleSidebar()"
  >
    <img
      *ngIf="!showMobileSidebar; else closeIcon"
      src="/assets/images/menu.svg"
      i18n-alt
      alt="Menu icon"
    />

    <ng-template #closeIcon>
      <img src="/assets/images/close.svg" i18n-alt alt="Close icon" />
    </ng-template>
  </button>
</div>

<!-- Mobile Sidebar -->
<div
  *ngIf="isMobileView && showMobileSidebar"
  class="absolute bottom-0 left-0 right-0 top-20 z-10 overflow-hidden"
>
  <div class="relative flex h-full w-full flex-col bg-white p-4">
    <div class="flex h-full max-h-[33rem] flex-col">
      <ul class="flex h-full flex-col justify-between pt-4">
        <li
          *ngFor="let item of items"
          class="flex flex-row items-center rounded-3xl px-4 py-5"
          [ngClass]="{ 'bg-alpha-blue-10': currentUrl === item.url }"
        >
          <a
            aria-hidden="true"
            (keypress.enter)="goTo(item.url)"
            (click)="goTo(item.url)"
            class="flex items-center"
            [ngClass]="{ 'cursor-pointer': currentUrl !== item.url }"
          >
            <div class="rounded-xl bg-alpha-green p-3 opacity-75">
              <img
                class="h-5 w-5"
                src="/assets/icons/{{ item.iconUrl }}.svg"
                i18n-alt
                alt="{{ item.title }} icon"
              />
            </div>

            <h3 class="ml-2 truncate text-lg md:text-xl">{{ item.title }}</h3>
          </a>
        </li>

        <li class="flex flex-row items-center rounded-3xl px-4 py-2">
          <button (click)="logout()" class="flex items-center">
            <div class="rounded-xl bg-alpha-green p-3 opacity-75">
              <img
                class="h-5 w-5"
                src="/assets/icons/logout.svg"
                i18n-alt
                alt="Logout icon"
              />
            </div>
            <h3 class="ml-2 truncate text-lg">Logout</h3>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Dashboard Sidebar -->
<div
  *ngIf="!isMobileView"
  class="flex h-full min-w-[22rem] basis-1/3 flex-col bg-white p-8 shadow"
>
  <img class="h-auth w-32" src="/assets/images/logo.svg" alt="" />

  <div class="flex h-full flex-col justify-between pt-16">
    <ul>
      <li
        *ngFor="let item of items; index as i"
        class="flex flex-row items-center rounded-3xl px-4 py-2"
        [ngClass]="{
          'mt-6': i > 0,
          'bg-alpha-blue-10': currentUrl === item.url,
        }"
      >
        <a
          aria-hidden="true"
          (keypress.enter)="goTo(item.url)"
          (click)="goTo(item.url)"
          class="flex items-center"
          [ngClass]="{ 'cursor-pointer': currentUrl !== item.url }"
        >
          <div class="rounded-xl bg-alpha-green p-3 opacity-75">
            <img
              class="h-8 w-8"
              src="/assets/icons/{{ item.iconUrl }}.svg"
              i18n-alt
              alt="{{ item.title }} icon"
            />
          </div>
          <h3 class="ml-2 truncate text-xl">{{ item.title }}</h3>
        </a>
      </li>

      <li class="mt-6 flex flex-row items-center rounded-3xl px-4 py-2">
        <button (click)="logout()" class="flex items-center">
          <div class="rounded-xl bg-alpha-green p-3 opacity-75">
            <img
              class="h-8 w-8"
              src="/assets/icons/logout.svg"
              i18n-alt
              alt="Logout icon"
            />
          </div>
          <h3 class="ml-2 truncate text-xl">Logout</h3>
        </button>
      </li>
    </ul>

    <div>
      <h3 class="mb-2 text-xl font-semibold">
        {{ user?.profile?.firstName }} {{ user?.profile?.lastName }}
      </h3>
      <p class="text-gray-600">
        {{ user?.activeOrganisation?.organisation?.name }}
      </p>
    </div>
  </div>
</div>
