import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';

import { User, UserProfile } from '../../interfaces/user.interface';
import { UserService } from '../../services/user.service';
import { handleHttpError } from '../../utils/api';
import { AppState } from '..';
import { UserActions } from '../actions/user.actions';

@Injectable()
export class UserEffects {
  public acceptInvite$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptInvite),
      switchMap((action) =>
        from(this.service.acceptInvite({ code: action.code })).pipe(
          map(() => UserActions.acceptInviteSuccess()),
          catchError((error) => of(UserActions.acceptInviteFailure({ error }))),
        ),
      ),
    ),
  );

  public acceptInviteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptInviteFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public acceptInviteSuccess$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.acceptInviteSuccess),
        tap(() => {
          this.store.dispatch(UserActions.me());
          this.router.navigateByUrl('/jobs', { replaceUrl: true });
        }),
      );
    },
    { dispatch: false },
  );

  public me$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.me),
      switchMap(() =>
        from(this.service.me()).pipe(
          map((data: User) => UserActions.meSuccess({ data })),
          catchError((error) => of(UserActions.meFailure({ error }))),
        ),
      ),
    );
  });

  public meFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.meFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public createProfile$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.createProfile),
      switchMap(({ payload }) =>
        from(this.service.createProfile(payload)).pipe(
          map((data: UserProfile) =>
            UserActions.createProfileSuccess({ data }),
          ),

          catchError((error) =>
            of(UserActions.createProfileFailure({ error })),
          ),
        ),
      ),
    );
  });

  public createProfileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createProfileFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public updateProfile$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateProfile),
      switchMap(({ payload }) =>
        from(this.service.updateProfile(payload)).pipe(
          map((data: UserProfile) =>
            UserActions.updateProfileSuccess({ data }),
          ),

          catchError((error) =>
            of(UserActions.updateProfileFailure({ error })),
          ),
        ),
      ),
    );
  });

  public updateProfileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateProfileFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: UserService,
    private readonly store: Store<AppState>,
  ) {}
}
