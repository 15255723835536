import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../../interfaces/user.interface';
import { UserActions } from '../actions/user.actions';

export interface UserState {
  error: unknown | null;
  pending: boolean;
  user: User | null;
}

export const initialState: UserState = {
  error: null,
  pending: false,
  user: null,
};

const userReducer = createReducer(
  initialState,
  on(UserActions.clearState, (): UserState => initialState),
  on(
    UserActions.acceptInvite,
    (state): UserState => ({ ...state, pending: true }),
  ),
  on(
    UserActions.acceptInviteSuccess,
    (state): UserState => ({
      ...state,
      error: null,
      pending: false,
    }),
  ),
  on(
    UserActions.acceptInviteFailure,
    (state, { error }): UserState => ({
      ...state,
      error,
      pending: false,
    }),
  ),
  on(UserActions.me, (state): UserState => ({ ...state, pending: true })),
  on(
    UserActions.meSuccess,
    (state, { data }): UserState => ({
      ...state,
      user: data,
      error: null,
      pending: false,
    }),
  ),
  on(
    UserActions.meFailure,
    (state, { error }): UserState => ({
      ...state,
      error,
      pending: false,
    }),
  ),
  on(
    UserActions.updateProfile,
    (state): UserState => ({ ...state, pending: true }),
  ),
  on(
    UserActions.updateProfileSuccess,
    (state, { data }): UserState => ({
      ...state,
      user: state.user
        ? {
            ...state.user,
            profile: data,
          }
        : null,
      error: null,
      pending: false,
    }),
  ),
  on(
    UserActions.updateProfileFailure,
    (state, { error }): UserState => ({
      ...state,
      error,
      pending: false,
    }),
  ),
);

export const reducer = (
  state: UserState | undefined,
  action: Action,
): UserState => userReducer(state, action);
