import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { AlertActions } from "../../store/actions/alert.action";
import { selectAllAlerts } from "../../store/selectors/alert.selectors";

@Component({
  selector: 'app-alerts',
  templateUrl: 'alerts.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class AlertsComponent implements OnDestroy {
  @Input() type: 'error' | 'success' | 'info' | undefined;
  alerts$ = this.store.select(selectAllAlerts);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  getAlertTitle(type: string): string {
    switch (type) {
      case 'error':
        return 'Error!';
      case 'warning':
        return 'Warning!';
      case 'success':
        return 'Success!';
      default:
        return 'Notice!';
    }
  }

  closeAlert(id: string): void {
     this.store.dispatch(AlertActions.delete({ id }));
  }

  ngOnDestroy() {
    this.store.dispatch(AlertActions.clearState());
  }
}
